import axios from 'axios'
import router from '../../router'

/**
 * initial state
 */
const state = () => ({
  // todo
})

/**
 * getters
 */
const getters = {
  // todo
}

/**
 * mutations
 */
const mutations = {
  // todo
}

/**
 * actions
 */
const actions = {
  //Tamjai Connect History
  tamjaiConnectHistory(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_TAMJAI_CONNECT_HISTORY +
            '?startDate=' +
            credentials.startDate +
            '&endDate=' +
            credentials.endDate +
            '&page=' +
            credentials.page +
            '&limit=' +
            credentials.limit +
            '&search=' +
            credentials.search +
            '&policy_new=' +
            credentials.policy_new
        )
        .then((response) => {
          console.log('API History Detail ::', response)
          console.log('API History Detail2 ::', response.data.isSuccess)
          if (response.status == 200 && response.data.isSuccess == true) {
            resolve(response.data)
          } else reject(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  uploadFile(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('fileUpload', credentials.file)
      axios
        .post(process.env.VUE_APP_TAMJAI_CONNECT_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  /**
   * View Quotation Detail
   * @param paTamjaiId
   * @return {array}
   */
  viewTamjaiConnect(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_TAMJAI_CONNECT_VIEW + '/' + credentials.id)
        .then((response) => {
          console.log('API view tamjai connect ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              if (response.data.data.id == null) {
                resolve('No content')
                console.error('No content')
                router.push({ name: 'history' })
              }
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          } else reject(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
