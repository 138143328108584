import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function load(component) {
  return () => import(`@/components/views/${component}.vue`)
}

function loadStaffModule(component) {
  return () => import(`@/components/staff-module/${component}.vue`)
}

function loadCmsModule(component) {
  return () => import(`@/components/cms-module/${component}.vue`)
}

function loadOrganiModule(component) {
  return () => import(`@/components/organi-module/${component}.vue`)
}
function loadTamjaiModule(component) {
  return () => import(`@/components/tamjai-module/${component}.vue`)
}

const routes = [
  {
    name: 'login',
    path: '/',
    component: load('Login'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    name: 'historyNew',
    path: '/historyNew',
    component: load('HistoryNew'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    name: 'history',
    path: '/history',
    component: load('History'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cancerPrompt.quotationView',
    path: '/cancer-prompt/quotation-view/:quotationId',
    component: load('CancerQuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cancerTamjai.quotationView',
    path: '/cancer-tamjai/quotation-view/:quotationId',
    component: load('CancerTamjaiQuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'paTamjai.quotationView',
    path: '/pa-tamjai/quotation-view/:quotationId',
    component: load('QuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'paPrompt.quotationView',
    path: '/pa-prompt/quotation-view/:quotationId',
    component: load('PromptQuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'autoPrompt.quotationView',
    path: '/auto-prompt/quotation-view/:quotationId',
    component: load('AutoQuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'homePrompt.quotationView',
    path: '/home-prompt/quotation-view/:quotationId',
    component: load('HomeQuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'quotationView',
    path: '/quotation-view/:quotationId',
    component: load('QuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'autoPrompt.editCarInfor',
    path: '/auto-prompt/carinfor-edit/:quotationId',
    component: load('AutoEditCarInfo'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staff',
    path: '/staff-management/index',
    component: loadStaffModule('Index'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffCreate',
    path: '/staff-management/create',
    component: loadStaffModule('Create'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffView',
    path: '/staff-management/view/:staffId',
    component: loadStaffModule('View'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffUpdate',
    path: '/staff-management/update/:staffId',
    component: loadStaffModule('Update'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffUpload',
    path: '/staff-management/upload',
    component: loadStaffModule('Upload'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cms',
    path: '/cms-management/index',
    component: loadCmsModule('Index'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsCreate',
    path: '/cms-management/create',
    component: loadCmsModule('Create'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsView',
    path: '/cms-management/view/:staffId',
    component: loadCmsModule('View'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsUpdate',
    path: '/cms-management/update/:staffId',
    component: loadCmsModule('Update'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsUpload',
    path: '/cms-management/upload',
    component: loadCmsModule('Upload'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'organiCreate',
    path: '/organi-management/create',
    component: loadOrganiModule('Create'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'organi',
    path: '/organi-management/index',
    component: loadOrganiModule('Index'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'organiView',
    path: '/organi-management/view/:organiId',
    component: loadOrganiModule('View'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'organiUpdate',
    path: '/organi-management/update/:organiId',
    component: loadOrganiModule('Update'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'HistoryHomeRenewalCMS',
    path: '/renew/history',
    component: load('HistoryHomeRenewalCMS'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'UploadFile',
    path: '/upload-file/view',
    component: load('UploadFile'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'UploadPayment',
    path: '/upload-payment/view',
    component: load('UploadPayment'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'organi',
    path: '/organi-management/index',
    component: loadOrganiModule('Index'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'homeRenewalCMS.quotationView',
    path: '/home-renewal/quotation-view/:quotationId',
    component: load('HomeRenawalCMSQuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  // Tamjai Connect
  {
    path: '/tamjai-connect',
    name: 'tamjaiConnect',
    component: () => import(/* webpackChunkName: "tamjaiConnect" */ '../components/tamjai-module/Index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'tamjaiConnect.view',
    path: '/view/:id',
    props: true,
    component: () => import(/* webpackChunkName: "tamjai-connect-view" */ '../components/tamjai-module/View.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tamjai-connect/upload-file',
    name: 'tamjaiConnect.uploadFile',
    component: loadTamjaiModule('UploadFile'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'NotFoundPage',
    path: '/:catchAll(.*)',
    component: load('NotFoundPage'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
})

/**
 * Check Authentication
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    if (!store.getters['staff/loggedIn']) {
      console.log('You are not logged in, go to login page...')
      next({
        name: 'login',
      })
    } else {
      console.log('Go to next page...')
      if (store.getters['staff/loggedIn']) {
        next()
      } else
        next({
          name: 'login',
        })
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route dont't requires auth, check if logged in
    if (store.getters['staff/loggedIn']) {
      console.log('You are logged in, go to history page...')
      if (store.getters['staff/loggedIn']) {
        next({
          name: 'history',
        })
      } else
        next({
          name: 'login',
        })
    } else {
      console.log('Visitor Go to login page...')
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
